import React, { useContext, useEffect, useState } from 'react';
import Box from '../../Common/Box';
import Typography from '../../Common/Typography';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, List, ListItemText, Paper } from '@material-ui/core';
import useStyles from './useStyles';
import { Partner, PartnerType } from '../../../models/partner.model';
import ListItem from '@material-ui/core/ListItem';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { Driver } from 'driver.js';
import { updateClient } from '../../../services/client.service';
import { AnalyticsContext } from '../../../common/analyticsContext';
import { AuthContext } from '../../../common/authContext';
import { TenantContext } from '../../../common/tenantContext';
import { AnalyticEventNames } from '../../../common/analyticEventNames.enum';

export default function PaymentPartners({partners, driverObj, clientId, partnerType}: {partners: Partner[], driverObj: Driver, clientId: string, partnerType: PartnerType})  {
  const classes = useStyles();
  const [type, setType] = useState<PartnerType>();
  const intl = useIntl();

  const {trackEvent} = useContext(AnalyticsContext);
  const {authUser} = useContext(AuthContext);
  const {tenant} = useContext(TenantContext);

  useEffect(() => {
    if (partnerType) {
      setType(partnerType);
    }
  }, [partnerType]);

  const storePartnerType: (typeSelected: PartnerType) => void = async (typeSelected) => {
    try {
      if (clientId) {
        await updateClient(clientId, {partnerType: typeSelected});
      }
    } catch (err) {
      console.log('Error saving type selected');
    }
  };

  const openPartnerUrl: (partner: Partner) => void = (partner: Partner) => {
    if (partner.partnerUrl) {
      trackEvent(AnalyticEventNames.PartnerInteraction, {
        partnerId: partner._id,
        partnerName: partner.name,
        partnerUrl: partner.partnerUrl,
        tenantId: tenant?._id,
        tenantName: tenant?.name,
        interactionBy: authUser?._id
      });
      window.open(partner.partnerUrl, '_blank');
    }
  }

  
  const typeSelected = (type: PartnerType) => {
    setType(type);
    if (driverObj.isActive()) {
      const activeElement = driverObj.getActiveElement();
      if (activeElement?.id === 'next-steps') {
        driverObj.refresh();
      }
    }
    storePartnerType(type);
  }
  
  const partnerListByType = () => {
    const filtered = partners.filter(partner => partner.type === type);
    if (!filtered?.length) return null;
    return (
      <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
        <List style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {filtered.map(partner => (
            <ListItem className={classes.partnerItem} key={`partner${partner._id}`} onClick={() => openPartnerUrl(partner)}>
              <Box className={classes.partnerImage}>
                <img style={{'objectFit': 'contain', 'height': '100%', 'maxWidth': '100px'}} alt={`partner${partner.name}`} src={partner.logoUrl}/>
              </Box>
              <ListItemText style={{textAlign: 'center'}} primary={partner.name} />
            </ListItem>
          ))}
        </List>
      </Box>
    )
  }

  return (
    <Paper id='next-steps' elevation={5}>
      <Box py={3}>
        <Typography variant="h5">
          <Box ml={3.125}>
            <FormattedMessage {...messages.nextSteps}/>
          </Box>
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' pb={3} px={3.125}>
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button onClick={() => typeSelected(PartnerType.Lender)} className={type === PartnerType.Lender ? classes.activeButton : undefined}>{intl.formatMessage(messages.lender)}</Button>
          <Button onClick={() => typeSelected(PartnerType.TitleCompany)} className={type === PartnerType.TitleCompany ? classes.activeButton : undefined}>{intl.formatMessage(messages.titleCo)}</Button>
          <Button onClick={() => typeSelected(PartnerType.Valuation)} className={type === PartnerType.Valuation ? classes.activeButton : undefined}>{intl.formatMessage(messages.profucts)}</Button>
        </ButtonGroup>
        {partnerListByType()}
      </Box>
    </Paper>
  );
}

PaymentPartners.propTypes = {
  partners: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    logoUrl: PropTypes.string,
    partnerUrl: PropTypes.string,
  })),
  driverObj: PropTypes.object,
  clientId: PropTypes.string,
  partnerType: PropTypes.string,
};
